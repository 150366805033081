import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StepOne from '../Steps/StepOne';
import StepTwo from '../Steps/StepTwo';
import StepThree from '../Steps/StepThree';

const Form = ({ step, setStep }) => {
  const [fields, setFields] = useState({});

  const onStepSubmit = (stepFields) => {
    setFields({ ...fields, ...stepFields });
    setStep(step + 1);
  };

  return (
    <>
      {step === 0 && <StepOne onSubmit={onStepSubmit} stepData={fields} />}
      {step === 1 && <StepTwo onSubmit={onStepSubmit} stepData={fields} />}
      {step === 2 && <StepThree setStep={setStep} stepData={fields} />}
    </>
  );
};

Form.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default Form;
