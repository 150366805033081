import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';
import { ExpandCollapse } from '@tds/core-expand-collapse';
import Box from '@tds/core-box';

export const Link = styled(ReactLink)`
    text-decoration: none;
`;

export const ErrorMsg = styled.div`
    border-radius: 4px;
    background-color: #fff6f8;
    display: block;
    flex-direction: column;
    margin: 1rem 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    letter-spacing: -0.6px;
    line-height: 1.42857;
    font-weight: 500;
    text-align: left;
`;

export const NormalText = styled.span`
    font-weight: 400;
`;

export const TermsAndConditions = styled.div`
    max-height: 350px;
    overflow-y: auto;
`;

export const SignatureContainer = styled.div`
    margin-top: 8px;
`;

export const ExpandCollapsePanel = styled(ExpandCollapse.Panel)`
    background-color: black !important;
`;

export const HeaderLinks = styled(Box)`
    margin-left: auto;
    margin-right: 15px;
`;

export const Break = styled.div`
    height: 10px;
`;