import React from 'react';

import { TermsAndConditions } from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <p>&nbsp;</p>
      <p>
        <span>
        Les modalités suivantes, y compris les modalités et conditions générales énoncées sur le site web sur les soins de santé virtuels de TELUS{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://telushealthvirtualcare.com/fr/mm.html"
          >
            https://telushealthvirtualcare.com/fr/mm.html
          </a>{' '}
          r&eacute;gissent l&apos;régissent l’utilisation des services de soins de santé virtuels décrits dans les présentes (la &laquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Services de soins de santé virtuels</strong>
        <span>&raquo;) que vous (le &laquo;</span>
        <strong style={{ fontWeight: 600 }}>Client</strong>
        <span>
          &raquo;avez commandés auprès de TELUS Communications inc.
          (&laquo;
        </span>
        <strong style={{ fontWeight: 600 }}>TELUS</strong>
        <span>
          &raquo;) afin qu’ils soient utilisés par vos Utilisateurs finaux autorisés. Vous pouvez télécharger, sauvegarder et imprimer une copie des présentes Conditions et Modalités d’affaire et des Conditions Générales (collectivement, le &laquo;{' '}
        </span>
        <strong style={{ fontWeight: 600 }}>Contrat </strong>
        <span>
          &raquo;) pour consultation ultérieure.
          r&eacute;f&eacute;rence.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
        En  cliquant  sur  « J’ACCEPTE »  ou  en  procédant  à  une  forme  d’acceptation  similaire  par  voie  électronique,  vous acceptez ces Conditions et Modalités d’affaire et les Conditions Générales. Si vous n’acceptez pas d’être lié par ces Conditions et Modalités d’affaire et les Conditions Générales, veuillez ne pas inclure un accès aux Services de soins de santé virtuels à votre forfait de services mobiles.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
          Les termes en majuscules utilis&eacute;s ci-dessous ont la
          signification qui leur est donn&eacute;e dans les Conditions
          G&eacute;n&eacute;rales.{' '}
        </span>
        <p>
          <strong style={{ fontWeight: 600 }}>
          Veuillez lire attentivement les Conditions Générales, car elles font partie du Contrat et énoncent les droits, obligations et limitations d’importance que vous devez accepter avant l’activation du compte. Les Conditions Générales comprennent, sans s’y limiter, les éléments suivants :
          </strong>
        </p>
      </p>{' '}
      <br />
      <p>
        <span>
        De temps à autre, TELUS peut modifier les Conditions Générales et si elles sont modifiées, TELUS publiera la version modifiée sur le site web de TELUS (voir le lien ci-dessus) et vous avisera de la modification   au moyen d’un avis à même votre facture, par courriel ou autrement. Sauf indication contraire de la part de TELUS, les Conditions Générales modifiées entrent en vigueur à la date de l’avis précité. Si vous n’acceptez pas les Conditions Générales modifiées, vous  devez  aviser  TELUS  par  écrit  dans  les  dix (10) jours  suivant  la  réception  de  l’avis;  faute  de  quoi,  vous  serez  réputé avoir accepté les Conditions Générales modifiées.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
        Services de soins de santé virtuels aux Utilisateurs finaux autorisés:
        </strong>
        <span>
          Le Client reconnaît que a) les Services de soins  de  santé  virtuels  aux  Utilisateurs  finaux  autorisés  sont  offerts  par  TELUS  et  ses  partenaires  (y  compris  le Partenaire de la plateforme, les Professionnels de la santé et les Professionnels des soins de santé complémentaires) par l’intermédiaire de l’Application de soins de santé virtuels et que cette utilisation est régie par les Modalités relative aux utilisateurs finaux; et b) l’utilisation de l’Application de soins de santé virtuels par les Utilisateurs finaux autorisés et  la  prestation  de  Services  de  soins  de  santé  virtuels  par  l’intermédiaire  de  l’Application  de  soins  de  santé  virtuels nécessitent que les Utilisateurs admissibles concluent les Modalités relatives aux utilisateurs finaux avec le Partenaire de la plateforme.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
        Le Client reconnaît et convient que les Services de soins de santé virtuels peuvent être modifiés selon les besoins en raison de modifications des modes de prestation de services de santé de qualité, de l’état actuel des connaissances en matière de pratiques médicales exemplaires, des lignes directrices provinciales en matière de santé, de la législation applicable  ou  des  exigences  en  matière  de  sécurité,  ou  autrement  selon  ce  que  TELUS  ou  ses  sociétés  affiliées estiment (à leur discrétion exclusive) raisonnable ou souhaitable.
        </span>
      </p>{' '}
      <br />
      {' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
        Vie  privée  et  utilisation  des  renseignements  des  Utilisateurs  admissibles:
        </strong>
        <span>
          Chaque  Utilisateur  admissible  qui accède aux Services de soins de santé virtuels doit accepter les Modalités relatives aux utilisateurs finaux et l’énoncé de  confidentialité  du  Partenaire  de  la  plateforme  alors  en  vigueur,  ainsi  que  leur  version  modifiée  de  temps  à  autre conformément  à  ces  Modalités  relatives  aux  utilisateurs  finaux  et  cet  énoncé  de  confidentialité  (collectivement, &laquo;&nbsp;
        </span>
        <strong style={{ fontWeight: 600 }}>politiques</strong>
        <span>
          &nbsp;&raquo;). Sauf  disposition  contraire  dans  les  Politiques,  TELUS  et  son  Partenaire  de  la  plateforme  doivent accéder  aux  Renseignements  personnels  des  Utilisateurs  admissibles,  les  recueillir,  les  utiliser  et  les  divulguer
          uniquement  dans  la  mesure  raisonnablement  nécessaire  pour  fournir  les  Services  de  soins  de  santé  virtuels,  pour obtenir les paiements ou pour fournir aux Utilisateurs admissibles des renseignements, des bulletins d’information, des invitations et d’autres communications de la part de  TELUS ou de son Partenaire de la plateforme  en lien avec les Services  de  soins  de  santé  virtuels,  et  selon  ce  qui  est  exigé  ou  permis  par  les  Lois  en  matière  de  protection  des renseignements  personnels  TELUS  et  son  Partenaire  de  la  plateforme  doivent  respecter  les  Lois  en  matière  de protection des renseignements personnels qui s’appliquent à l’égard des Renseignements personnels.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
        Droits  de  propriété  intellectuelle:
        </strong>
        <span>
         Entre  le  Client,  d’une  part,  et  TELUS  ainsi  que  ses  fournisseurs  (y  compris  le Partenaire de la plateforme, les Professionnels de la santé et les Professionnels des soins de santé complémentaires), d’autre part, TELUS ou ses fournisseurs détiennent et conservent tous les droits de propriété intellectuelle actuels et futurs (et toutes les demandes, déclarations et inscriptions connexes) relatifs aux Services de soins de santé virtuels, à l’Application de soins de santé virtuels, à la documentation et à tous les éléments susceptibles de bénéficier d’une protection légale, aux œuvres dérivées, aux modifications et aux améliorations apportées à l’ensemble des éléments précités, que ces éléments soient développés ou non en collaboration avec le Client, et qu’ils soient développés ou non par TELUS et ses fournisseurs ou le Client, ou par tout entrepreneur, sous-traitant ou mandataire de TELUS et de ses fournisseurs ou du Client. Rien dans le Contrat ne peut entraîner le transfert de la propriété ou la concession d’une licence au Client en vertu des droits de propriété intellectuelle de TELUS ou de ses sociétés affiliées.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
         Durée des Services de soins de santé virtuels
        </strong>
        <span>
          {' '}
          :  La Date de début est la date à laquelle le Client a accepté le Contrat établi avec TELUS. La Date de fin est la même date que la « Date d’expiration de l’entente » de l’Entente de service du Client avec TELUS.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
        Garantie et exonération de responsabilité
        </strong>
        <span>
          .&nbsp; Sauf disposition contraire aux présentes et dans la mesure permise par la  loi,  les  Services  de  soins  de  santé  virtuels,  l’Application  de  soins  de  santé  virtuels  et  les  données  (y  compris  les données de tiers), les renseignements ou les services consultés, utilisés ou fournis en lien avec le Contrat sont fournis « tels quels » et « sous réserve de leur disponibilité ». TELUS et ses sociétés affiliées, y compris le Partenaire de la plateforme,  nient  l’ensemble  des  déclarations,  des  conditions  et  des  garanties  légales,  explicites  ou  implicites,  y compris,  mais  sans  s’y  limiter,  les  garanties  implicites  de  qualité  marchande,  d’exactitude,  d’intégrité,  de  liberté,  de non-contrefaçon  ou  d’adaptation  à  un  usage  particulier,  ou  découlant  des  modalités  d’exécution  ou  des  pratiques commerciales courantes, ou selon lesquelles les Services de soins de santé virtuels ou l’Application de soins de santé virtuels seront exempts d’erreurs ou d’interruptions.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>
        Limitation de responsabilité
        </strong>
        <span>
          &nbsp;: TELUS et ses sociétés affiliées, y compris son Partenaire de la plateforme, et leurs administrateurs, employés, entrepreneurs ou mandataires respectifs n’assument aucune responsabilité envers le Client ou  toute  autre  personne  découlant  du  Contrat  ou  s’y  rapportant  en  ce  qui  concerne  a) des  dommages  particuliers, indirects,  accessoires  ou  consécutifs,  b) toute  perte  de  données  ou  de  profits  ou  toute  interruption  des  activités découlant du Contrat ou s’y rapportant, en vertu de toute théorie, prévisibles ou non, peu importe s’ils ont été informés ou  non  de  la  possibilité  de  tels  dommages,  c) une  somme  globale  supérieure  à  100,00 $  ou  aux  frais  payés  par  le Client pour un mois pour la Solution de soins primaires virtuels en vertu de l’Entente de service, calculés à partir de la moyenne des trois mois ayant précédé le premier événement à l’origine de la réclamation du Client à l’encontre de TELUS se rapportant à la Solution de soins primaires virtuels ou au Contrat, selon le plus élevé des deux montants. Aux  fins  du  présent  article,  les  frais  payés  par  le  Client  pour  la  Solution  de  soins  primaires  virtuels  sont  fixés  à quatre dollars et cinquante cents par mois par Personne admissible. Le présent article survit à la résiliation du Contrat.
        </span>
      </p>{' '}
      <br />
      <p>
        <strong style={{ fontWeight: 600 }}>Arbitrage : </strong>
        <span>
          &nbsp; TELUS et le Client auront recours à l’arbitrage pour régler leurs différends découlant du Contrat ou encore liés  à  celui-ci,  peu  importe  que  ces  différends  reposent  sur  une  base  contractuelle,  extracontractuelle  (y  compris  la négligence),  législative  ou  autre.  Si  les  représentants  des  parties  ne  parviennent  pas  à  régler  un  tel  différend,  une partie  doit  soumettre  le  différend  à  un  arbitrage  privé  et  confidentiel  devant  un  arbitre  unique  afin  qu’il  soit  tranché. L’arbitrage  aura  lieu  dans  la  capitale  de  la  province  où  le  Client  est  situé,  conformément  aux  règles  de  l’Institut d’arbitrage et de médiation du Canada, y compris ses règles relatives à l’introduction et à la présentation d’un différend, à  la  nomination  d’un  arbitre  et  à  la  responsabilité  des  honoraires  et  des  débours  découlant  de  l’arbitrage  ou  s’y rapportant. Le présent article ne s’applique pas au recouvrement de toute somme due à TELUS ou à toute demande d’injonction; toutefois, dans le cas d’une demande d’injonction, les parties doivent avoir recours à l’arbitrage afin de régler le différend qui a donné lieu à la demande. Toute procédure judiciaire liée au Contrat doit être engagée devant un tribunal compétent de l’Ontario.
        </span>
      </p>{' '}
      <br />
      <p>
        <span>
        En cas de conflit ou d’incohérence entre les Conditions et Modalités d’affaire et les Conditions Générales en ligne, les Conditions Générales en ligne prévalent.
        </span>
      </p>{' '}
      <br />
      <p>&nbsp;</p> <br />
    </TermsAndConditions>
  );
};

export default Terms;
