import React from 'react';
import { ExpandCollapse } from '@tds/core-expand-collapse';
import Box from '@tds/core-box';
import Heading from '@tds/core-heading';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { NormalText } from '../../styles/elements';
import { dataKeys, fieldNames } from '../../config/constants';

const Summary = ({ stepData }) => {
  const [t] = useTranslation();
  const { ADMIN, THATSME } = dataKeys;
  const {
    legalBusinessName,
    billingAccountNumber,
    firstName,
    lastName,
    email,
    mobileNumber,
    title,
    plan,
    term,
    offer,
    eligibleEmployees,
    province,
    preferredLanguage

  } = fieldNames;

  const getLabelText = (fieldName) => {
    switch (fieldName) {
      case firstName:
        return t('FIRST_NAME');
      case lastName:
        return t('LAST_NAME');
      case legalBusinessName:
        return t('LEGAL_BUSINESS_NAME');
      case billingAccountNumber:
        return t('BILLING_ACCOUNT_NUMBER');
      case email:
        return t('EMAIL');
      case mobileNumber:
        return t('MOBILE_NUMBER');
      case title:
        return t('TITLE');
      case plan:
        return t('PLAN');
      case term:
        return t('TERM');
      case offer:
        return t('OFFER');
      case eligibleEmployees:
        return t('ELIGIBLE_EMPLOYEES');
      case province:
        return t('PROVINCE');
      case preferredLanguage:
        return t('PREFERRED_LANGUAGE');
      default:
        return fieldName;
    }
  };
  const productDetails = {
    plan: t('PRODUCT_PLAN'),
    term: t('PRODUCT_TERM'),
    offer: t('PRODUCT_OFFER'),
    eligibleEmployees: t('PRODUCT_ELIGIBLE_EMPLOYEES')
  };

  return (
    <Box horizontal={3}>
      <ExpandCollapse
        tag="h2"
        open={['aboutYou', 'assignAnAdmin', 'productDetails']}
      >
        <ExpandCollapse.Panel id="aboutYou" header={t('STEP_1')}>
          <Box between={3}>
            {Object.keys(stepData).map(
              (key) =>
                key !== ADMIN && (
                  <Box between={2} key={`mainKey${key}`}>
                    <Heading level="h4">
                      {getLabelText(key)} :{' '}
                      <NormalText>{stepData[key]}</NormalText>
                    </Heading>
                  </Box>
                )
            )}
          </Box>
        </ExpandCollapse.Panel>

        <ExpandCollapse.Panel id="assignAnAdmin" header={t('STEP_2')}>
          <Box between={3}>
            {stepData.adminData &&
              Object.keys(stepData.adminData).map(
                (key) =>
                  key !== THATSME && (
                    <Box between={2} key={`adminKey${key}`}>
                      <Heading level="h4">
                        {getLabelText(key)} :{' '}
                        <NormalText>{stepData.adminData[key]}</NormalText>
                      </Heading>
                    </Box>
                  )
              )}
          </Box>
        </ExpandCollapse.Panel>

        <ExpandCollapse.Panel id="productDetails" header={t('PRODUCT_DETAILS')}>
          <Box between={3}>
            {productDetails &&
              Object.keys(productDetails).map((key) => (
                <Box between={2} key={`productDetail${key}`}>
                  <Heading level="h4">
                    {getLabelText(key)} :{' '}
                    <NormalText>{productDetails[key]}</NormalText>
                  </Heading>
                </Box>
              ))}
          </Box>
        </ExpandCollapse.Panel>
      </ExpandCollapse>
    </Box>
  );
};

Summary.propTypes = {
  stepData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Summary;
