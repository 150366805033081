import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import Routes from './routes';
import i18n from './config/i18n';

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Routes />
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
