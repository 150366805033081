import React from 'react';
import FlexGrid from '@tds/core-flex-grid';
import Box from '@tds/core-box';
import { HeaderBar, LogoBar } from '../../styles/header';

import Logo from './logo';
import { HeaderLinks, Link } from '../../styles/elements';
import { languageTypes } from '../../config/constants';
import { useLocation } from 'react-router-dom';

const getPageNameWithoutLocale = () => {
  const pathname = window.location.pathname;
  const queryParam = window.location.search;
  const [, pageName] = pathname.substr(1, pathname.length).split('/');
  return (pageName && `${pageName}${queryParam}`) || '';
};

const Header = () => {
  const pageName = getPageNameWithoutLocale();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const getLatestNo = () => {
    return parseInt(query.get('ban'))
  }
  return (
    <Box>
      <HeaderBar>
        <FlexGrid limitWidth>
          <FlexGrid.Row>
            <Box inset={3}>
              <LogoBar>
                <Logo />
              </LogoBar>
            </Box>
            <HeaderLinks>
              <Link to={`/${languageTypes.en}/${pageName}?ban=${getLatestNo() ? getLatestNo() : 'XXXXXXXXXXXXX'}`}>En</Link>
              &nbsp; | &nbsp;
              <Link to={`/${languageTypes.fr}/${pageName}?ban=${getLatestNo() ? getLatestNo() : 'XXXXXXXXXXXXX'}`}>Fr</Link>
            </HeaderLinks>
          </FlexGrid.Row>
        </FlexGrid>
      </HeaderBar>
    </Box>
  );
};

export default Header;
