import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../locale/en.json';
import translationFR from '../locale/fr.json';
import { languageTypes } from './constants';

// the translations
const resources = {
	[languageTypes.en]: {
		translation: translationEN
	},
	[languageTypes.fr]: {
		translation: translationFR
	}
};

i18n
	.use(initReactI18next)
	.init({
		resources,
		lng: languageTypes.en,
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;