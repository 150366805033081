import * as Yup from 'yup';

const phoneRegExp = /^(\d)(?!\1+$)\d{9}$/;

const commonSchema = (t) => ({
    firstName: Yup.string()
        .required(t('FIRSTNAME_REQUIRED')),
    lastName: Yup.string()
        .required(t('LASTNAME_REQUIRED')),
    email: Yup.string()
        .email(t('EMAIL_INVALID'))
        .required(t('EMAIL_REQUIRED')),
    mobileNumber: Yup.string()
        .required(t('MOBILE_REQUIRED'))
        .test("is-mobile-number", t('MOBILE_INVALID'), (value) => {
            const number = value && value.replace(/[-() _]/g, '');
            return phoneRegExp.test(number);
        })
});

export const getStepOneSchema = (t) => {
    return Yup.object().shape({
        legalBusinessName: Yup.string()
            .required(t('LEGAL_BUSINESS_NAME_REQUIRED')),
        ...commonSchema(t)
    });
};

export const getStepTwoSchema = (t) => {
    return Yup.object().shape({
        ...commonSchema(t),
        province: Yup.string()
            .required(t('PROVINCE_REQUIRED')),
        preferredLanguage: Yup.string()
            .required(t('PREFERRED_LANGUAGE_REQUIRED'))
    });
};

