export const scrollTop = () => {
    return window.scrollTo(0, 0);
};

export const getProvinces = (t) => {
    const provinces = [
        "ALBERTA",
        "BRITISH_COLUMBIA",
        "MANITOBA",
        "NEW_BRUNSWICK",
        "NEWFOUNDLAND_AND_LABRADOR",
        "NORTHWEST_TERRITORIES",
        "NOVA_SCOTIA",
        "NUNAVT",
        "ONTARIO",
        "PRINCE_EDWARD_ISLAND",
        "QUEBEC",
        "SASKATCHEWAN",
        "YUKON",
    ].map(op => t(op)).map((op) => ({ text: op, value: op }));

    return provinces;
};

export default {}; 