import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '@tds/core-heading';
import Text from '@tds/core-text';
import Box from '@tds/core-box';

import Container from '../../components/Container';
import Logo from '../../components/Header/logo';
import { scrollTop } from '../../utils';

const SuccessScreen = () => {
  const [t] = useTranslation();

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <Container>
      <Box between={3} inset={3}>
        <Heading level="h2">{t('THANK_YOU')}</Heading>
        <Text>
          <Text bold>{t('SUCCESS_PAGE.TEXT_1')}</Text> -{' '}
          {t('SUCCESS_PAGE.TEXT_2')}{' '}
        </Text>
        <Text>
          <Text bold>{t('SUCCESS_PAGE.TEXT_3')}</Text>{' '}
          {t('SUCCESS_PAGE.TEXT_4')}
        </Text>
        <br />
        <div>
          <Logo height="60px" />
        </div>
      </Box>
    </Container>
  );
};

export default SuccessScreen;
