import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import App from '../components/App';
import { languageTypes } from '../config/constants';

const Routes = () => {
  return (
    <Route path="/">
      <Route exact path="/">
        <Redirect to={`/${languageTypes.en}/`} />
      </Route>
      <Route path="/:locale">
        <App />
      </Route>
    </Route>
  );
};

export default Routes;
