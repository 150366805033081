import React from 'react';

import { TermsAndConditions } from '../../styles/elements';

const Terms = () => {
  return (
    <TermsAndConditions>
      <p>&nbsp;</p>
      <p>
        <span>
        The  following  terms,  including  the  General  Terms  and  Conditions  set  out  at  the  TELUS  Virtual  Care  Website:{' '}
        </span>{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href=" telushealthvirtualcare.com/mm"
        >
           telushealthvirtualcare.com/mm 
        </a>{' '}
        <span>
        govern  the  use  of  the  virtual  care  services  described  herein (the
          &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Virtual Care Services</strong>
        <span>&rdquo;) you (the &ldquo;</span>
        <strong style={{ fontWeight: 600 }}>Client&rdquo;</strong>
        <span>) have ordered from TELUS Communications Inc. (&ldquo;</span>
        <strong style={{ fontWeight: 600 }}>TELUS</strong>
        <span>
          &rdquo;) for use by your Authorized End  Users.    A  copy  of  these  Business  Terms  of  Use  and  the  General  Terms  and  Conditions  (collectively  the  &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Agreement</strong>
        <span>
          &rdquo;) may be downloaded, saved and printed for your
          reference.&nbsp;&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
        By clicking “I AGREE” or similar electronic acceptance, you agree with these Business Terms of Use and the General Terms and Conditions. If you do not accept and agree to be bound by these Business Terms of Use and the General Terms and Conditions, please do not include access to the Virtual Care Services to your wireless plan.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
        Capitalised terms used below shall have the meaning given to them in the General Terms and Conditions.
        </span>
        <strong style={{ fontWeight: 600 }}>
          {' '}
          Please carefully read the  General  Terms  and  Conditions  as  they  form  part  of  this  agreement  and  contain  important  legal  rights, restrictions and obligations which must be accepted by you prior to activating the account.   These General Terms and Conditions include, but are not limited to, the following: 
        </strong>{' '}
      </p>
      <p>
        <span>
        From time to time TELUS may amend General Terms and Conditions, and if amended, TELUS will post the amended version on the TELUS Website (see link above) and provide you with notice of the change by invoice notification, email or otherwise. Unless TELUS indicates otherwise, the effective date of the amended General Terms and Conditions will be the date of the notification. If you do not agree with amended General Terms and Conditions you must notify TELUS in  writing  within  ten  (10)  days  of  receipt  of  the  notification,  failing  which  you  will  be  deemed  to  have  accepted  the amended General Terms and Conditions.
        </span>
      </p>
      <p>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p>
        <strong style={{ fontWeight: 600 }}>Virtual Care Services to Authorized End Users: </strong>
        <span>The Client acknowledges that (a) the Virtual Care Services to the Authorized End Users are provided by TELUS and its partners (including its Platform Partner, Health Professionals and Allied Health Practitioners) via the Virtual Care App and such use is governed by the End User Terms; and (b) use of the Virtual Care App by Authorized End Users and the supply of any Virtual Care Services through the Virtual Care App requires Eligible Users to enter into End User Terms with the Platform Partner.</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>The Client acknowledges and agrees that the Virtual Care Services may be modified from time to time due to changes in  the  methods  of  quality  health  service,  current  knowledge  of  best  medical  practices,  provincial  health  guidelines, applicable law or safety requirements, or otherwise in such manner in which TELUS or its’ affiliates considers (in its sole discretion) are reasonable or desirable.</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
        Client Obligations:{' '}
        </strong>
        <span>
        Client is invited to share a complete list of all Eligible Individuals 
        (the “</span>
        <strong style={{ fontWeight: 600 }}>Eligibility List</strong>
        <span>”)
         to TELUS (and/or the Platform Partner) within 10 days of the acceptance of this Agreement, to maximize use of the Virtual Care Services.  Client  is  further  obligated  to  maintain  the  accuracy  of  the  Eligibility  List  and  to  advise  TELUS  (and/or  its Platform Partner) of any and all changes to this Eligibility List in a timely manner, but no later than 30 days following any  deletion  of  employees  to  the  Eligibility  List.  Eligibility  List  information  shall  include,  at  minimum,  the  Eligible Individual’s name, French or English language preference, province or territory of residence, email address, and unique identifier (e.g. 10-digit phone number associated with the wireless services).   During the term of the Agreement, the maximum number of Eligible Individuals shall not exceed the total quantity of phone numbers on the Client’s account with TELUS for whom the Client has subscribed to a wireless voice and data rate plan under the CSA Agreement on the date the CSA Agreement has been executed.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
        Privacy and Use of Eligible User Information
        </strong>
        <span>
          {' '}
          Each Eligible User accessing the Virtual Care Services will be required to accept the Platform Partner’s privacy statement and End User Terms then in effect, as amended from time to time in accordance with such privacy statement and End User Terms (collectively, the &ldquo;
        </span>
        <strong style={{ fontWeight: 600 }}>Policies</strong>
        <span>
          &rdquo;).&nbsp;Unless otherwise set out in the Policies, TELUS and its Platform Partner will access, collect, use and disclose the Personal Information of Eligible Users only as reasonably necessary to provide the Virtual Care Services, to obtain payment and/or to provide Eligible Users with information, newsletters, invitations and other communications from TELUS or its Platform Partner in relation to the Virtual Care Services, and as required or permitted by Privacy Laws.  TELUS and its Platform Partner will comply with applicable Privacy Laws relating to Personal Information.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
        Intellectual Property Rights:&nbsp;{' '}
        </strong>
        <span>
        As between Client on the one hand and TELUS and its providers (including the Platform Partner, Health Professionals, and Allied Health Practitioners) on the other hand,  TELUS and/or its providers shall own and  retain  all  intellectual  property  rights,  whether  currently  existing  or  hereafter  developed  or  acquired,  (and  all applications, disclosures and registrations with respect thereto) in connection with and to the Virtual Care Services, the Virtual  Care  App,  any  documentation,  and  all  legally  protectable  elements,  derivative  works,  modifications  and enhancements thereto, whether or not developed in conjunction with Client, and whether or not developed by TELUS
        and its providers, Client or any contractor, subcontractor or agent for TELUS and its providers or Client. Nothing in the Agreement shall cause the transfer of ownership of, or grant a license, under any intellectual property rights of TELUS or its affiliates, to the Client.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>Virtual Care Services Term: </strong>
        <span>
        The Start Date begins on the date the Client has accepted the Agreement with TELUS. The End Date is the same date as the "Agreement End Date" of the CSA Agreement.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
        Warranty and Disclaimer:&nbsp;{' '}
        </strong>
        <span>
        Except as expressly set forth herein and to the extent permitted by law, the Virtual Care Services, the Virtual Care App, and any data (including third party data), information or services accessed or provided in connection with the Agreement are provided “as is” and “as available”. TELUS and its affiliates including its Platform Partner, disclaim all statutory representations, conditions and warranties, express or implied, including but not limited to any implied warranties of merchantability, accuracy, integrity, freedom, non-infringement or fitness for a particular purpose, or arising from the course of performance or course of dealing, or that the Virtual Care Services or the Virtual Care App will provide error-free or uninterrupted functionalities.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>
        Limitation  of  Liability:&nbsp;{' '}
        </strong>
        <span>
        In  no  event  will  TELUS,  its  affiliates  including  its  Platform  Partner,  and  their  respective directors, employees, contractors or agents be liable to the Client or to any other entity arising out of or related to the Agreement (a) for any special, indirect, incidental or consequential damages, (b) for any loss of data or profit or business interruption, arising out of or related to the Agreement, under any theory, whether or not foreseeable, whether or not advised of the possibility of such damage, (c) in an aggregate amount exceeding $100.00 or one month of charges paid by the Client for the Virtual Primary Care Solution under the CSA Agreement, calculated as an average over the three month period immediately preceding the first event that gave rise to any claim by the Client against TELUS arising from or relating to the Virtual Primary Care Solution or the Agreement, whichever is greater. For the purpose of this section, charges paid by the Client for the Virtual Primary Care Solution is set at four dollars and fifty cents per month per Eligible Individual. This section shall survive the termination of the Agreement.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong style={{ fontWeight: 600 }}>Arbitration:&nbsp; </strong>
        <span>
        TELUS and the Client shall use arbitration to resolve disputes between them arising from or relating to the  Agreement,  whether  the  dispute  is  based  in  contract,  tort  (including  negligence),  statute,  or  otherwise.  If  the business representatives of the parties have not been able to resolve any such dispute, in order to proceed with the dispute a party must submit the dispute to private and confidential arbitration before a single arbitrator.  Arbitration will take place in the capital of the province where the Client is located under the rules of the ADR Institute of Canada, including its rules as to initiation and submission of a dispute, appointment of an arbitrator, and responsibility for the fees and expenses arising from or relating to arbitration. This Section does not apply to the collection of any amounts owing to TELUS or any injunction application, except that, in the case of an injunction application, the parties shall use arbitration to resolve the dispute that led to the application. Venue and jurisdiction of any court proceedings shall be in the province of Ontario.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
        If there is any conflict or inconsistency between the Business Terms of Use and the General Terms and Conditions on-line, the General Terms and Conditions on-line will take precedence.
        </span>
      </p>
    </TermsAndConditions>
  );
};

export default Terms;
